<template>
  <CRow class="zq--form-row">
    <CCol
        :col="colLabel"
        :sm="smLabel"
        :md="mdLabel"
        :lg="lgLabel"
        :xl="xlLabel"
        v-if="isLabel"
        class="zq--form-row--label">
      <div class="mb-1">
        <span class="zq--form-row--label-text">{{ label }}</span>
        <IconWithTooltip class="position-relative" v-if="tooltipPosition === 'label'" :text="toolTipText.length ? toolTipText : 'This is a description'"/>
      </div>

    </CCol>
    <CCol
        :col="colContent"
        :sm="smContent"
        :md="mdContent"
        :lg="lgContent"
        :xl="xlContent"
        class="zq--form-row--content">
      <slot name="content"></slot>
      <IconWithTooltip v-if="tooltipPosition === 'content' && isTooltip" :text="toolTipText.length ? toolTipText : 'This is a description'"/>
    </CCol>
  </CRow>
</template>

<script>
import IconWithTooltip from '@/shared/UI/IconWithTooltip';

export default {
  props: {
    label: String,
    isLabel: {
      type: Boolean,
      default: true
    },
    isTooltip: {
      type: Boolean,
      default: true
    },
    toolTipText: {
      type: String,
      default: 'This is a description'
    },
    tooltipPosition: {
      type: String,
      default: 'content'
    },
    colContent: {
      type: String,
      default: '11'
    },
    smContent: {
      type: String,
      default: '11'
    },
    mdContent: {
      type: String,
      default: '11'
    },
    lgContent: {
      type: String,
      default: '11'
    },
    xlContent: {
      type: String,
      default: '6'
    },
    // LABEL
    colLabel: {
      type: String,
      default: '12'
    },
    smLabel: {
      type: String,
      default: '3'
    },
    mdLabel: {
      type: String,
      default: '3'
    },
    lgLabel: {
      type: String,
      default: '3'
    },
    xlLabel: {
      type: String,
      default: '2'
    }
  },
  components: {
    IconWithTooltip
  }
}
</script>

<style lang="scss">
.zq--form-row.row {
  margin: 16px 0;

  .zq--form-row--label {
    text-align: left;
    font-style: italic;
    padding-left: 0;
  }

  .zq--form-row--content {
    padding-left: 0;
  }

  .fa-question-circle-o {
    position: absolute;
    top: 0;
    right: -10px;
  }

  .zq--row-switch__tooltip {
    position: relative;
    width: fit-content;
  }
}
</style>