<template>
  <CRow class="zq-page-title zq-child-page-header">
    <CCol
        col="12"
        class="zq--form-header"
    >
      <div class="zq-create-page-title-wrapper d-flex">
        <h3 class="zq-child-title-text zq-create-title" v-if="title">{{ title }}</h3>
        <IconWithTooltip class="mt-2" :text="descriptions.pageTitle"/>
      </div>
      <div class="create--header-actions">
        <CButton
            :color="theme === 'default' ? 'secondary' : ''"
            type="button"
            :disabled="isButtonDisabled"
            :class="{'zq--form-header--not-main': !isMainAction}"
            class="mr-3 zq--custom-button"
            @click="$router.go(-1)"
        >
          {{ $t('buttons.cancel') }}
        </CButton>
        <CButton
            :disabled="isButtonDisabled"
            class="action-create-button zq--responsive-button__common"
            :class="{'zq--form-header--not-main': !isMainAction}"
            :color="theme === 'default' ? 'success' : ''"
            type="submit">
          {{ $t(submitLabel) }}
        </CButton>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import {mapGetters} from 'vuex';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';

export default {
  components: {
    IconWithTooltip
  },
  props: {
    title: String,
    submitTitle: {
      type: String,
      default: 'create'
    },
    isMainAction: {
      type: Boolean,
      default: true
    },
    descriptions: {
      type: Object,
      default() {
        return {}
      }
    },
    loading: Boolean
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
    submitLabel() {
      switch (this.submitTitle) {
        case 'create' : {
          return 'buttons.create';
        }
        case 'update' : {
          return 'buttons.update';
        }
        case 'add' : {
          return 'buttons.add';
        }
        default: {
          return 'buttons.create';
        }
      }
    }
  }
}
</script>

<style lang="scss">
.zq--form-header {
  &--not-main {
    opacity: .6;
  }
}
.zq-create-title {
  padding-right: 15px !important;
}
</style>
