<template>
  <CButton
      variant="outline"
      :color="colorBtn"
      :disabled="disabled"
      @click="handleClick"
      :class="{'zq--custom-button--responsive__icon': !text.length, 'zq--custom-button--responsive__label': !icon}"
      class="zq--custom-button zq--custom-button--responsive"
  >
    <!--   ICON LEFT   -->
    <span
        v-if="icon"
        class="zq--custom-button--icon__left">
    <slot name="left-icon"></slot>
    </span>
    <!--  TEXT  -->
    <span
        v-if="text"
        :class="{'zq--custom-button--text__responsive': responsive}"
        class="zq--custom-button--text">
      {{ text }}
    </span>
    <!--   ICON RIGHT   -->
    <span
        v-if="icon"
        :class="{'zq--custom-button--icon__single': !text.length}"
        class="zq--custom-button--icon__right">
    <slot name="right-icon"></slot>
    </span>
  </CButton>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    disabled: Boolean,
    color: {
      type: String,
      default: ''
    },
    responsive: {
      type: Boolean,
      default: false
    },
    icon: Boolean,
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    colorBtn() {
      if (this.color) {
        return this.color;
      }

      switch (this.theme) {
        case 'main': {
          return ''
        }
        case 'default': {
          return 'dark'
        }
        default: {
          return ''
        }
      }
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/mixins.scss';

.zq--custom-button.zq--custom-button--responsive {
  height: 37px;

  .zq--custom-button--text {
    &__responsive {
      @include media-breakpoint-down('576px') {
        display: none;
      }
    }
  }

  svg {
    fill: #636f83;
  }

  .zq--custom-button--icon {
    &__left {
      margin-right: 8px;
      @include media-breakpoint-down('576px') {
        margin-right: 0;
      }
    }

    &__right {
      margin-left: 8px;
      @include media-breakpoint-down('576px') {
        margin-left: 0;
      }
    }

    &__single {
      margin-right: 0;
      margin-left: 0;
    }

  }

  &:focus {
    box-shadow: none;
  }

  &:hover {
    svg {
      fill: #FFFFFF;
      color: #FFFFFF;
    }
  }

  @include media-breakpoint-down('576px') {
    padding: 0;
    height: 37px;
    width: 37px;
  }
}

.zq--custom-button.zq--custom-button--responsive__icon {
  width: 37px;
  padding: 0;

  .zq--custom-button--icon {
    &__left {
      margin-right: 0;

    }

    &__right {
      margin-left: 0;

    }
  }
}

.btn.zq--custom-button.zq--custom-button--responsive__label {
  width: fit-content;
  padding: .375rem .75rem;
}
</style>