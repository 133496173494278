<template>
  <div
    class="zq--select"
    :style="{width: width}"
    :class="{'zq--select__error': isValid === false && theme === 'main'}"
    ref="multiselect"
  >
    <div v-if="theme === 'main'" class="multiselect-wrapper">
      <multiselect
        v-if="isDescription"
        v-model="value"
        :placeholder="placeholder"
        :options="options"
        :searchable="searchable"
        :selectLabel="selectLabel"
        :selectedLabel="selectedLabel"
        :disabled="disabled"
        :allowEmpty="false"
        :style="{width: width}"
        :trackBy="trackBy"
        :label="labelTrack"
        class="no-overflow"
        :class="{
          'zq--select--error': isValid === false,
          'zq--select--no-press-btn': !isPressBtn,
          'zq--select--no-overflow': isDescription
        }"
        @select="updateValue"
        :multiple="multiple"
      >
        <template slot="singleLabel" slot-scope="props">
          <span class="option__desc">
            <span class="option__title">{{ props.option.label }}</span>
          </span>
        </template>
        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title">{{ props.option.label }}</span>
            <span class="description">{{ props.option.description }}</span>
          </div>
        </template>
      </multiselect>
      <div v-else>
        <multiselect
          v-model="value"
          :placeholder="placeholder"
          :options="options"
          :searchable="searchable"
          :selectLabel="selectLabel"
          :selectedLabel="selectedLabel"
          :disabled="disabled"
          :allowEmpty="false"
          :style="{width: width}"
          :trackBy="trackBy"
          :label="labelTrack"
          :class="{'zq--select--error': isValid === false, 'zq--select--no-press-btn': !isPressBtn}"
          @select="updateValue"
          :multiple="multiple"
        />
        <div class="zq-required-message">{{requiredText}}</div>
        <span v-if="isValid === false" class="icon-validation-error">{{ invalidFeedback }}</span>
      </div>
    </div>
    <CSelect
      v-else
      id="zq--select"
      :label="label"
      :placeholder="placeholder"
      :options="options"
      size="sm"
      :style="{width: width}"
      :value="value"
      @update:value="updateValue"
      :horizontal="{
        label: 'col-1',
        input: 'col-12',
      }"
      :required="required"
      :disabled="disabled"
      :invalidFeedback="invalidFeedback"
      :is-valid="isValid"
    />
  </div>

</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'ClSelect',
  props: {
    options: Array,
    valueProp: [Array, Number, Object, String],
    placeholder: {
      type: String,
      default: ''
    },
    selectLabel: {
      type: String,
      default: ''
    },
    deselectLabel: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    selectedLabel: {
      type: String,
      default: ''
    },
    searchable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    trackBy: String,
    labelTrack: String,
    disabled: {
      type: Boolean,
      default: false
    },
    invalidFeedback: {
      type: String,
      default: 'Required'
    },
    isValid: {
      type: Boolean,
      default: true
    },
    isDescription: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    isPressBtn: {
      type: Boolean,
      default: false
    },
    requiredText: {
      type: String,
      default: 'Required'
    },
  },
  data() {
    return {
      value: null
    }
  },
  created() {
    if (this.theme === 'main') {
      const val = this.valueProp ? this.options.find((item) => (item.value || item) === (this.valueProp.value || this.valueProp)) : '';
      this.value = val;
      const data = val.value ? val : {value: val, label: null};

      this.$emit('checkValue', data);
    } else {
      const val = this.valueProp ? (this.valueProp.value || this.valueProp) : '';
      this.value = val;
      const data = val.value ? val : {value: val, label: null};
      this.$emit('checkValue', data)
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    width() {
      if (this.size === 'sm' && this.theme === 'main') {
        return '62px'
      }
      else if (this.size === 'sm') {
        return '90px'
      } else if (this.size === 'xxl') {
        return 'auto'
      }
      return ''
    },
  },
  methods: {
    updateValue(val) {
      const data = val.value ? val : {value: val, label: null};
      this.value = val;
      this.$refs.multiselect.parentNode.classList.remove('zq-invalid');
      this.$emit('checkValue', data)
    }
  },
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";
.zq--select--no-overflow {
  .multiselect__content-wrapper {
    overflow: unset !important;
  }
  .multiselect__element {
    position: relative;
    &:hover {
      .description {
        display: flex;
      }
    }
  }
}
.option__desc {
  .description {
    position: absolute;
    right: -210px;
    top: -10px;
    z-index: 999;
    width: 200px;
    display: none;
    align-items: center;
    padding: 5px 16px;
    min-height: 48px;
    border-radius: 12px;
    box-shadow: 0 1px 10px 0 rgba(150, 109, 103, 0.1);
    background-color: #505a66;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #ffffff !important;
    &:before {
      content: '';
      z-index: 9999;
      position: absolute;
      left: -6px;
      top: 20px;
      display: block;
      width: 0.4rem;
      height: 0.8rem;
      border-color: transparent;
      border-style: solid;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-left-color: #505a66;
      border-right-color: #505a66;
    }
  }
}
.multiselect__single {
  text-align: left;
}
.zq--select {
  &__error {
    margin-bottom: 14px;
    .icon-validation-error {
      position: relative;
      top: -5px;
    }
  }
}
.zq-invalid > .zq--select > .multiselect-wrapper > .multiselect > .multiselect__tags{
  border: solid 1px var(--zq-warn) !important;
}

.zq-invalid > .zq--select > .multiselect-wrapper > div > .multiselect > .multiselect__tags{
  border: solid 1px var(--zq-warn) !important;
}
.zq-required-message {
  display: none;
}
.zq-invalid > .zq--select > .multiselect-wrapper > .zq-required-message {
  display: block;
}
.icon-validation-error {
  left: 0;
  position: absolute;
  bottom: -15px;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #d14334;
  white-space: nowrap;
  @include media-breakpoint-down(xs) {
    left: 11em;
    bottom: 10px;
  }
}
</style>
