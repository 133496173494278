<template>
  <div class="zq--page-toolbar">
    <div
      :class="isLeftSide ? 'zq--page-toolbar--left': 'zq--page-toolbar--right'"
    >
      <!--    Additional Param    -->
      <ClCustomButton
          v-if="isAdditionalParam"
          icon
          class="additional-param--button zq--page-toolbar--item"
          :class="{'additional-param--button__pressed' : showParam}"
          text="Additional param"
          @click="handleToggleParam">
        <template #left-icon>
          <CIcon :name="setIcon(showParam)" />
        </template>
      </ClCustomButton>
      <!--   TOGGLE EDITOR   -->
      <ClCustomButton
          icon
          class="zq--page-toolbar--item zq--page-toolbar--item__last"
          text="WYSIWYG editor"
          @click="handleToggleEditor"
          :class="{'zq--editor--switcher__pressed': isToolbar}"
          :disabled="disabled">
        <template #left-icon>
          <CIcon :name="setIcon(isToolbar)" />
        </template>
      </ClCustomButton>
    </div>
  </div>
</template>

<script>
import ClCustomButton from "@/shared/components/formComponents/ClCustomButton";

export default {
  components: {
    ClCustomButton
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isLeftSide: {
      type: Boolean,
      default: false
    },
    isAdditionalParam: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isToolbar: false,
      showParam: false
    }
  },
  computed: {
    editorIcon() {
      return this.isToolbar ? 'cil-minus' : 'cil-plus';
    },
  },
  methods: {
    handleToggleEditor() {
      this.isToolbar = !this.isToolbar;
      this.$emit('toggleEditorToolbar', this.isToolbar);
    },
    handleToggleParam() {
      this.showParam = !this.showParam;
      this.$emit('toggleAdditionalParam', this.showParam);
    },
    setIcon(state) {
      return state ? 'cil-minus' : 'cil-plus';
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";

.zq--page-toolbar {
  display: flex;

  &--left {
    flex: 1;
  }

  &--right {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    @include media-breakpoint-down('385px') {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  //
  .zq--editor--switcher {
    &:hover {
      .zq--editor--toggle-label {
        color: #FFFFFF;
      }
    }
  }

  .zq--editor--switcher__pressed.btn.zq--custom-button.zq--custom-button--responsive {
    color: #fff;
    background-color: #636f83;
    border-color: #636f83;

    .zq--custom-button--text {
      color: #fff;
    }
  }

  // ITEM
  .zq--page-toolbar--item {
    margin-right: 1rem;

    @include media-breakpoint-down('576px') {
      margin-bottom: .5rem;
    }
    @include media-breakpoint-down('385px') {
      margin-right: 0;
    }

    &__last {
      margin-right: 0;
    }
  }
}
</style>