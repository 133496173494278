<template>
  <CRow class="pb-3 edit-actions">
    <CCol col="12" class="d-flex justify-content-end">
      <CButton
        :color="theme === 'default' ? 'secondary' : ''"
        type="button"
        class="mr-3 zq--custom-button"
        @click="$router.go(-1)"
      >
        Go back
      </CButton>
      <CButton
        v-if="isShowUpdate"
        class="action-create-button zq--responsive-button__common"
        :color="theme === 'default' ? 'success' : ''"
        type="submit"
        @click="handleUpdate"
      >
        Update
      </CButton>
      <CDropdown
        inNav
        class="c-header-nav-items custom-dropbox zq--actions-dropdown"
        placement="bottom-end"
        add-menu-classes="pt-0"
        v-if="isShowDropdown"
      >
        <template #toggler class="justify-content-end">
          <CButton
            class="zq--custom-button"
            :color="colorBtn"
            variant="outline">
            {{$t('buttons.actions')}}
            <i
              v-if="theme === 'main'"
              class="fa fa-angle-down font-weight-bold"></i>
            <i v-else class="fa fa-caret-down"></i>
          </CButton>
        </template>
        <CDropdownItem class="p-0">
          <CButton
            pill
            :color="colorBtn"
            class="action-custom-button"
            variant="ghost"
            @click="handleEditSettings"
          >
            Edit Settings
          </CButton>
        </CDropdownItem>
        <CDropdownItem class="p-0">
          <CButton
            pill
            :color="colorBtn"
            class="action-custom-button"
            variant="ghost"
            @click="handleEditTranslations"
          >
            Edit Translations
          </CButton>
        </CDropdownItem>
      </CDropdown>
    </CCol>
  </CRow>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'ActionsForEdit',
  props: {
    isShowDropdown: Boolean,
    isShowUpdate: {
      type: Boolean,
      default: true,
    },
  },
  computed:{
    ...mapGetters('theme', ['theme']),
    deleteActionTitle(){
      if(this.deleteTitle === 'archive') {
        return 'buttons.Archived'
      }
      return  'buttons.delete'
    },
    colorBtn() {
      switch (this.theme) {
        case 'main': {
          return ''
        }
        case 'default': {
          return 'dark'
        }
        default: {
          return ''
        }
      }
    }
  },
  methods: {
    handleEditTranslations() {
      this.$emit('editTranslations')
    },
    handleEditSettings() {
      this.$emit('editSettings')
    },
    handleUpdate() {
      this.$emit('entityUpdate')
    },
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";
.edit-actions {
  .custom-dropbox {
    list-style: none;
    .dropdown-menu {
      min-width: 90px;
    }
  }
  .action-custom-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  @include media-breakpoint-up('768px') {
    .action-create-button {
      margin-right: 1rem;
    }
  }
}
</style>