/**
 * Transform array of languages to data object
 * example :
 * FROM ['GERMAN']
 * TO   {
 *   German:{
 *     name: '',
 *     description: ''
 *   }
 * }
 *
 * */
export const getLanguagesData = (languages) => {
  return languages.reduce((acc, item) => {
    return {
      ...acc,
      [item]: {
      }
    }
  }, []);
}

// Transform Translation formData to request field
export const translationsTransform = (
    data,
    map,
    isUpdate = false,
    languagesKeyIdMap = {},
    entityId,
    entityType
) => {
  const langKeys = Object.entries(data).filter(item => Object.keys(item[1]).length);
  return langKeys.map(item => {
    if (isUpdate) {
      return {
        id: item[1].id ? item[1].id : '',
        entityId: entityId,
        entityType: entityType,
        languageKey: item[0],
        languageId: languagesKeyIdMap[item[0]],
        translations: Object.keys(item[1]).filter(item => item !== 'id').map(field => {
          return {
            fieldName: field,
            text: item[1][field]
          }
        })
      }
    } else {
      return {
        languageKey: map[item[0]],
        translations: Object.keys(item[1]).map(field => {
          return {
            fieldName: field,
            text: item[1][field]
          }
        })
      }
    }
  });
}

export const translationsViewTransform = (data, languages, isEdit = false) => {
  let result = {};

  if (data.length) {
    data.forEach(item => {
      let translations = {};

      item.name = languages.filter(lang => lang.key === item.languageKey).map(lang => lang.name)[0];

      if (item.translations.length) {
        item.translations.forEach(translation => {
          translations[translation.fieldName] = translation.text;
        })

        item.name ? result[item.name] = translations : result[item.languageKey] = translations;

        if (isEdit) {
          result[item.languageKey].id = item.id;
        }
      }
    })
  }

  return result;
}