<template>
  <CInput
      @input="input"
      :name="name"
      :type="type"
      :add-input-classes="addInputClasses"
      :placeholder="placeholder"
      :readonly="isReadOnly"
      :disabled="isDisabled"
      :value="value"
      :is-valid="isValid"
      :invalidFeedback="invalidFeedback"
      :required="required"
      class="zq-input-text"
      :class="{'zq-input-text--disabled': isReadOnly && !isDisabled, classProp: classProp, 'disabled-input': disabledInput}"
  />
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "ClRowInput",
    props: {
      'add-input-classes': {
        type: String,
        default: 'col-sm-12'
      },
      'is-valid': {
        type: Boolean,
        default: true
      },
      type: {
        type: String,
        default: 'text'
      },
      name: {
        type: String,
        default: undefined
      },
      value: String,
      placeholder: String,
      disabled: {
        type: Boolean,
        default: false
      },
      required: Boolean,
      invalidFeedback: String,
      disabledInput: Boolean,
      classProp: {
        type: String,
        default: ''
      },

    },
    methods: {
      input(val) {
        this.$emit('input', val)
      }
    },
    computed: {
      ...mapGetters('theme', ['theme']),
      isReadOnly() {
        return (this.theme !== 'default' && this.disabled);
      },
      isDisabled() {
        return (this.theme === 'default' && this.disabled)
      }
    }
  }
</script>

<style lang="scss">
  .disabled-input{
    pointer-events: none;
    input.form-control {
      background-color: transparent;
      box-shadow: none;
      border: solid 1px rgba(0, 0, 0, 0.05) !important;
      pointer-events: none !important;
    }
  }
</style>