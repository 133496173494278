<template>
  <div class="create-scheduling">
    <CForm
      ref="createMember"
      novalidate
      class="create-form"
    >
      <div class="form-content">
        <ZqAwardScheduling
          :value="formData"
          :label="'Scheduling'"
          :tooltip="'Scheduling tooltip'"
          :disabled="false"
          :hidden="false"
          :placeholder="'placeholder'"
          @input="updateScheduling"
        />
      </div>
    </CForm>
  </div>
</template>

<script>
import {cloneDeep} from 'lodash';
import DatePicker from '@/shared/components/DatePicker';
import ClFormRow from "@/shared/components/formComponents/ClFormRow";
import ClSelect from "@/shared/components/formComponents/ClSelect";
import {formConfig} from "@/config";
import {achievements} from "@/config/descriptions/achievements";
import {awardsTexts} from "@/config/pageTexts/awards.json";
import ZqAwardScheduling from "@/shared/components/ZqAwardScheduling.vue";

export default {
  name: 'EditAwardScheduling',
  components: {
    ZqAwardScheduling,
    DatePicker,
    ClFormRow,
    ClSelect
  },
  props: {
    schedulingData: Array,
    isDisabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      texts: {
        ...awardsTexts
      },
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      startDate: '',
      endDate: null,
      onStatusChangeTo: '',
      onStatusChangeToOptions: ['Issued', 'Claimed', 'Completed', 'Held', 'Processing', 'Delivered', 'Expired', 'Cancelled', 'Declined'],
      formData: [
        {
          thenAwardActiveFrom: '',
          thenAwardActiveUntil: '',
          onStatusChangeTo: ''
        }
      ],
      fromValidate: null,
      descriptions: {
        achievementAvailableFrom: achievements.list.create.scheduling.achievementAvailableFrom,
        till: achievements.list.create.scheduling.till,
        membersCanAchieveOn: achievements.list.create.scheduling.membersCanAchieveOn
      }
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.formData = cloneDeep(this.schedulingData);
    },
    updateScheduling(data) {
      this.formData = data;
    },
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        this.$emit('updateSchedulingData', val);
      },
    },
  },
  destroyed() {
    this.$emit('resetValidate');
  }
}
</script>

<style lang="scss">
@import "~@coreui/coreui/scss/coreui";

.create-scheduling {
  height: 100%;
  .create-form {
    height: 100%;
  }
  .form-content {
    background: var(--zq-sub-bg);
    height: 100%;
    .zq--data-picker.mx-datepicker {
      width: 100%;
      min-width: 100%;
    }
  }
  .every {
    display: flex;
    flex-wrap: wrap;
    .every-date-el {
      background: #f1f1f1 none repeat scroll 0 0;
      border: 1px solid #f1f1f1;
      cursor: pointer;
      float: left;
      font-size: 16px;
      line-height: 30px;
      margin: 0 2px 2px 0;
      min-width: 45px;
      padding: 0 3px;
      text-align: center;
    }
    .active {
      background: #e4e3e3 none repeat scroll 0 0;
      border: 1px solid #aaaaaa;
      position: relative;
      &:after {
        content: "+";
        font-size: 11px;
        line-height: 7px;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}
</style>
