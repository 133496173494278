<template>
  <ClFormRow :label="label" :toolTipText="tooltip" :isLabel="isLabel" :isTooltip="isTooltip" ref="cInputWrapper">

    <template #content>
      <CInput
        ref="formInput"
        :placeholder="placeholder"
        v-model="txt"
        :value="txt"
        add-input-classes="col-sm-12"
        :disabled="disabled"
        :type="type"
        :name="name"
        :isrequired="required"
        :min="min"
        :max="max"
        :step="step"
        @input="onInput"
        @blur="blur"
      />
      <div class="zq-required-message">{{localRequiredText ? localRequiredText : requiredText}}</div>
    </template>
  </ClFormRow>
</template>

<script>
export default {
  name: 'CIInput',
  props: {
    value: [String, Number, Date, Array],
    label: String,
    tooltip: String,
    placeholder: String,
    disabled: Boolean,
    isLabel: {
      type: Boolean,
      default: true,
    },
    isTooltip: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      default: '',
    },
    min: {
      type: [String, Number],
      default: '',
    },
    max: {
      type: [String, Number],
      default: '',
    },
    step: {
      type: [String, Number],
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    requiredText: {
      type: String,
      default: 'Required',
    },
    isTranslation: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: [String, Number],
      default: '',
    }
  },
  data() {
    return {
      txt: null,
      intModels: ['delay', 'period'],
      localRequiredText: ''
    };
  },
  created() {
    if (this.value) {
      if (Array.isArray(this.value)) {
        this.txt = this.value.join(', ')
      } else {
        this.txt = this.value;
      }
    }
  },
  methods: {
    onInput(val) {
      if (this.intModels.includes(this.name)) {
        if (typeof val === 'string') {
          this.txt = val.replace(/[^0-9.]/g, '');
          if (this.txt) {
            this.txt = parseInt(this.txt);
          }
        } else {
          this.txt = Math.trunc(val);
        }

        this.$refs.formInput.$el.getElementsByTagName('input')[0].value = this.txt;
      }
    },
    blur() {
      this.$emit('blur');
      this.$emit('interaction', true)
    },
  },
  watch: {
    value(n, o) {
      if (n !== o) {
        this.txt = this.value;
      }
    },
    txt() {
      if (this.$refs.cInputWrapper && this.$refs.cInputWrapper.$children.length) {
        this.$refs.cInputWrapper.$children[0].$el.classList.remove('zq-invalid');
      }

      if (this.maxLength && this.txt.length > this.maxLength) {
        this.$refs.cInputWrapper.$children[0].$el.classList.add('zq-invalid');
        this.localRequiredText = `The max amount of the text permitted ${this.maxLength} characters`

        if (this.txt.length > this.maxLength + 1) {
          this.txt = this.txt.substring(0, this.maxLength);
        }
      }

      if (this.name !== '' && this.isTranslation) {
        this.$emit('input', {name: this.name, value: this.txt});
      } else {
        this.$emit('input', this.txt);
      }
    },
  },
};
</script>

<style lang="scss">
.zq--checkbox--wrap {
  .zq--checkbox {
    display: flex;
    align-items: center;
  }

  .toggle__text {
    line-height: normal;
  }

  .toggle__label {
    display: flex;
    align-items: center;
    margin-left: 0.2rem;
  }
}
.zq-required-message {
  color: var(--zq-warn);
  font-size: 12px;
  display: none;
}
.zq-invalid > input {
  border-color: var(--zq-warn) !important;
}
.zq-invalid + .zq-required-message {
  display: block;
}
</style>
